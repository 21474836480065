<template>
  <div class="form-login-video-reset">
    <p class="form-login-video-reset__text">{{ $tf('not') }} {{ realName }}?</p>
    <el-button type="text" v-text="$tf('sign_in_as_another_user')" @click="dispatchResetEvent" />
  </div>
</template>

<script>
export default {
  name: 'FormLoginVideoHelpReset',
  props: { realName: { type: String, required: true } },
  methods: {
    dispatchResetEvent() {
      this.$emit('reset');
    }
  }
};
</script>

<style lang="stylus">
.form-login-video-reset {
  line-height: 1.875;
  color: rgba(255, 255, 255, 0.5);

  &__text {
    margin: 0;
  }
}
</style>
