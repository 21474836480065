<template>
  <div class="page-login-card">
    <div class="page-login-card__logo">
      <slot name="logo" />
    </div>
    <div class="page-login-card__header">
      <slot name="header" />
    </div>
    <div class="page-login-card__body">
      <slot name="video_stream_button" />
      <slot name="video_stream_alternative_hint" />
      <slot name="form_login_basic" />
    </div>
  </div>
</template>

<script>
export default { name: 'PageLoginCard' };
</script>

<style lang="stylus">
.page-login-card {
  display: grid;
  grid-gap: 3.125rem 3.75rem;
  grid-template-columns: auto 34.375rem;

  &__logo, &__header {
    display: flex;
    align-items: flex-end;
  }

  &__body {
    display: grid;
    grid-template-columns: 16rem;
    grid-row-gap: 1.4125rem;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
}
</style>
