<template>
  <el-form ref="form" :model="model" :rules="rules" class="form-login-video-input-password">
    <el-form-item prop="password">
      <p class="form-login-video-input-password__hint" v-text="$tf('additional_security_measure')"></p>
      <input-password v-model="model.password" @submit="dispatchInputEvent" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" v-text="$tf('sign_in')" class="form-login-video-input-password__submitter" @click="dispatchInputEvent" />
    </el-form-item>
  </el-form>
</template>

<script>
import { symbolAsciiRule } from '@/apps/common/validator';
import { Component, Vue } from 'vue-property-decorator';
import InputPassword from '../common/input-password';

const BaseRules = {
  password: [
    {
      required: true,
      type: 'string',
      message: 'error.required.password',
      trigger: 'change'
    },
    symbolAsciiRule
  ]
};

@Component({ components: { InputPassword } })
export default class FormLoginVideoInputPassword extends Vue {
  model = { password: '' };

  get rules() {
    return this.$applyRuleMessages(BaseRules);
  }

  dispatchInputEvent() {
    this.$refs.form.validate((valid) => valid && this.$emit('input', this.model.password));
  }
}
</script>

<style lang="stylus">
.form-login-video-input-password {
  &__hint {
    font-size: 0.75rem;
    line-height: normal;
    color: rgba(255, 255, 255, 0.3);
  }

  &__submitter {
    width: 100%;
  }
}
</style>
