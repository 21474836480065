<template>
  <el-button size="small" type="primary" v-text="$tf('back')" @click="dispatchClickEvent" />
</template>

<script>
export default {
  name: 'FormLoginVideoCancelButton',
  methods: {
    dispatchClickEvent() {
      this.$emit('click');
    }
  }
};
</script>
