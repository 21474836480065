<template>
  <div class="form-login-video-layout">
    <div class="form-login-video-layout__preview">
      <slot name="preview" />
      <div v-if="$slots.cancel_button" class="form-login-video-layout__cancel-button">
        <slot name="cancel_button" />
      </div>
      <div v-if="$slots.state" class="form-login-video-layout__state">
        <slot name="state" />
      </div>
    </div>
    <div v-if="$slots.second_factor" class="form-login-video-layout__second-factor">
      <slot name="second_factor" />
    </div>
  </div>
</template>

<script>
export default { name: 'FormLoginVideoLayout' };
</script>

<style lang="stylus">
.form-login-video-layout {
  display: flex;
  justify-content: center;

  &__second-factor {
    margin-left: 3.75rem;
  }

  &__preview {
    flex: none;
    position: relative;
    max-width: 25em;
  }

  &__cancel-button, &__state {
    margin-top: 1.25rem;
  }

  &__cancel-button {
    text-align: center;
  }

  &__state {
    width: 100%;
    position: absolute;
  }
}
</style>
