<template>
  <h1 class="form-login-video-greeting">{{ $tf('hi') }}, {{ realName }}!</h1>
</template>

<script>
export default {
  name: 'FormLoginVideoGreeting',
  props: { realName: { type: String, required: true } }
};
</script>

<style>
.form-login-video-greeting {
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.95);
}
</style>
