<template>
  <form-login-video-layout>
    <form-login-video-preview slot="preview" :stream="stream" />
    <form-login-video-cancel-button slot="cancel_button" v-if="isCancelButtonVisible" @click="dispatchCancelEvent" />
    <form-login-video-second-factor-layout slot="second_factor" v-if="isSecondFactorVisible">
      <form-login-video-greeting slot="greeting" :real-name="token.real_name" />
      <form-login-video-input-password slot="password" @input="dispatchSubmitEvent" />
      <form-login-crypto-pro slot="crypto_pro" v-if="$store.state.config.plugins.cproauth" @submit="dispatchSubmitCryptoProEvent" />
      <form-login-video-reset slot="reset" @reset="handleResetEvent" :real-name="token.real_name" />
    </form-login-video-second-factor-layout>
    <form-login-video-state v-else slot="state" :state="state" />
  </form-login-video-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { isErrorResponse, isObject } from './common';
import FormLoginCryptoPro from './form-login-crypto-pro';
import FormLoginVideoCancelButton from './form-login-video-cancel-button';
import FormLoginVideoGreeting from './form-login-video-greeting';
import FormLoginVideoInputPassword from './form-login-video-input-password';
import FormLoginVideoLayout from './form-login-video-layout';
import FormLoginVideoPreview from './form-login-video-preview';
import FormLoginVideoReset from './form-login-video-reset';
import FormLoginVideoSecondFactorLayout from './form-login-video-second-factor-layout';
import FormLoginVideoState from './form-login-video-state';
import { recordVideo } from './media-devices-helpers';
import { AuthenticationState, AuthenticationType, notifyOnError } from './page-login-helpers';

const LoginDelayTimeout = 2000;
const DefaultVideoLength = 1500;

@Component({
  props: {
    stream: { type: MediaStream, required: true },
    authenticationType: { type: String, required: true },
    videoLength: { type: Number, default: DefaultVideoLength }
  },
  components: {
    FormLoginCryptoPro,
    FormLoginVideoCancelButton,
    FormLoginVideoGreeting,
    FormLoginVideoInputPassword,
    FormLoginVideoLayout,
    FormLoginVideoPreview,
    FormLoginVideoReset,
    FormLoginVideoSecondFactorLayout,
    FormLoginVideoState
  }
})
export default class FormLoginVideo extends Vue {
  state = AuthenticationState.IDLE;
  timer = null;
  token = null;

  get isOnlyUserFaceRequired() {
    return [AuthenticationType.FACE, AuthenticationType.FACE_OR_PASSWORD].includes(this.authenticationType);
  }

  get isSecondFactorVisible() {
    return this.authenticationType === AuthenticationType.FACE_AND_PASSWORD && isObject(this.token);
  }

  get isCancelButtonVisible() {
    return this.authenticationType === AuthenticationType.FACE_OR_PASSWORD;
  }

  mounted() {
    this.login();
  }

  beforeDestroy() {
    clearInterval(this.timer);
  }

  async login() {
    try {
      const response = await this.tryToLogin();
      if (this.isOnlyUserFaceRequired) {
        return this.dispatchLoggedInEvent(response);
      }
      this.token = response;
    } catch (e) {
      if (isErrorResponse(e)) {
        return this.handleError(e);
      }
      notifyOnError(this, e);
    }
  }

  async tryToLogin() {
    this.state = AuthenticationState.USER_FACE_VIDEO_RECORDING;
    const video = await this.recordUserFaceVideo();
    this.state = AuthenticationState.USER_FACE_IDENTIFICATION;
    const token = await this.loginByRecordedUserFaceVideo(video);
    this.state = AuthenticationState.USER_FACE_IDENTIFIED;
    return token;
  }

  recordUserFaceVideo() {
    return recordVideo(this.stream, this.videoLength);
  }

  loginByRecordedUserFaceVideo(video) {
    return this.$store.dispatch(this.$store.state.users.Action.LoginByVideo, video);
  }

  handleResetEvent() {
    this.token = this.state = null;
    this.login();
  }

  handleError() {
    this.timer = setTimeout(this.login, LoginDelayTimeout);
    this.state = AuthenticationState.IDLE;
  }

  dispatchSubmitEvent(password) {
    this.$emit('submit', {
      video_auth_token: this.token.token,
      login: this.token.username,
      password
    });
  }

  dispatchSubmitCryptoProEvent(payload) {
    this.$emit('submit', payload);
  }

  dispatchLoggedInEvent(response) {
    this.$emit('loggedin', response);
  }

  dispatchCancelEvent() {
    clearInterval(this.timer);
    this.$emit('cancel');
  }
}
</script>
