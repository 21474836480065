export const AuthenticationType = {
  FACE: 'face',
  PASSWORD: 'password',
  FACE_OR_PASSWORD: 'face_or_password',
  FACE_AND_PASSWORD: 'face_and_password'
};

export const AuthenticationState = {
  USER_FACE_VIDEO_RECORDING: 'user_face_video_recording',
  USER_FACE_IDENTIFICATION: 'user_face_identification',
  USER_FACE_IDENTIFIED: 'user_face_identified',
  IDLE: 'idle'
};

const VideoAuthenticationTypes = [AuthenticationType.FACE, AuthenticationType.FACE_OR_PASSWORD, AuthenticationType.FACE_AND_PASSWORD];

export function isVideoAuthenticationEnabled(authenticationType) {
  return VideoAuthenticationTypes.includes(authenticationType);
}

export function notifyOnError(instance, e) {
  const message = instance.$createElement('message-box', { props: { e } });
  instance.$notify({ duration: 0, message: message });
}
