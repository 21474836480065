<template>
  <div class="form-login-video-second-factor-layout">
    <div class="form-login-video-second-factor-layout__greeting">
      <slot name="greeting" />
    </div>
    <slot name="password" />
    <div v-if="$slots.crypto_pro" class="form-login-video-second-factor-layout__crypto-pro">
      <slot name="crypto_pro" />
    </div>
    <div class="form-login-video-second-factor-layout__reset">
      <slot name="reset" />
    </div>
  </div>
</template>

<script>
export default { name: 'FormLoginVideoSecondFactorLayout' };
</script>

<style lang="stylus">
.form-login-video-second-factor-layout {
  max-width: 21.875rem;

  &__reset {
    margin-top: 4.1875rem;
  }

  &__greeting {
    margin-bottom: 0.625rem;
  }

  &__crypto-pro {
    margin-top: 0.625rem;
  }
}
</style>
