<template>
  <video class="form-login-video-preview" autoplay />
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  props: {
    stream: { type: MediaStream, required: true }
  }
})
export default class FormLoginVideoPreview extends Vue {
  @Watch('stream', { immediate: true })
  handleStreamChange() {
    this.$nextTick(() => (this.$el.srcObject = this.stream));
  }
}
</script>

<style>
.form-login-video-preview {
  width: 25em;
  height: 18.75em;
  object-fit: cover;
  border-radius: 0.75em;
}
</style>
