<template>
  <el-button type="secondary" @click="sign" :loading="this.state.loading" v-text="$tf('sign_in | cryptopro')" />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class FormLoginCryptoPro extends Vue {
  get state() {
    return this.$store.state.cryptopro;
  }

  get dialog() {
    return this.$store.state.dialog.cryptopro;
  }

  sign() {
    if (!this.$cryptopro?.hasPlugin) {
      this.$notify({ type: 'error', message: this.$tf('error.crypto_pro.plugin_install') });
      return;
    }

    this.state.loading = true;
    this.$store
      .dispatch('getCertificates')
      .then(() => {
        this.dialog.enabled = true;
        this.dialog.action = this.generateSign;
      })
      .catch(this.notifyError)
      .finally(() => (this.state.loading = false));
  }

  generateSign() {
    this.dialog.enabled = false;
    this.state.loading = true;
    this.$store
      .dispatch('loginWithCryptopro')
      .then(this.dispatchSubmitEvent)
      .catch(this.notifyError)
      .finally(() => (this.state.loading = false));
  }

  dispatchSubmitEvent(payload) {
    this.$emit('submit', payload);
  }

  notifyError(e) {
    this.$notify({
      duration: 0,
      message: this.$createElement('message-box', { props: { e } })
    });
  }
}
</script>
