<template>
  <p class="form-login-video-state" :class="classes" v-text="message"></p>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationState } from './page-login-helpers';

@Component({
  props: {
    state: { type: String, required: true }
  }
})
export default class FormLoginVideoState extends Vue {
  messages = {
    [AuthenticationState.USER_FACE_VIDEO_RECORDING]: () => {
      return this.$tf('video_recording_in_progress');
    },
    [AuthenticationState.USER_FACE_IDENTIFICATION]: () => {
      return this.$tf('face_recognition_in_progress');
    }
  };

  get classes() {
    return {
      'form-login-video-state_processing': [AuthenticationState.USER_FACE_VIDEO_RECORDING, AuthenticationState.USER_FACE_IDENTIFICATION].includes(this.state),
      'form-login-video-state_error': this.state === AuthenticationState.ERROR
    };
  }

  get message() {
    const fn = this.messages[this.state];
    return (fn && fn()) || '';
  }
}
</script>

<style lang="stylus">
.form-login-video-state {
  margin: 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.3);

  &_processing::after {
    content: '';
    position: absolute;
    animation: processing 2s linear infinite;
  }
}

@keyframes processing {
  25% {
    content: '.';
  }

  50% {
    content: '..';
  }

  75% {
    content: '...';
  }
}
</style>
