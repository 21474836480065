<template>
  <p class="page-login-video-stream-alternative-hint" v-text="$tt('or_enter_login_password')"></p>
</template>

<script>
export default { name: 'PageLoginVideoStreamAlternativeHint' };
</script>

<style>
.page-login-video-stream-alternative-hint {
  margin: 4.25rem 0 1.5625rem;
  color: rgba(255, 255, 255, 0.5);
}
</style>
