<template>
  <div class="page-login-layout">
    <div class="page-login-layout__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default { name: 'PageLoginLayout' };
</script>

<style lang="stylus">
.page-login-layout {
  padding: 0 !important;

  &__content {
    padding: 2rem;
    min-width: 100%;
    min-height: 100%;
    width: max-content;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
  }
}
</style>
