<template>
  <el-input :type="type" v-model="model" :placeholder="$tf('password')" @keydown.native.enter.stop.prevent="dispatchSubmitEvent" class="input-password">
    <i slot="suffix" :class="eyeIconClassName" class="input-password-icon" @click.stop="togglePasswordVisibility"></i>
    ></el-input
  >
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

const EyeIconClassNamePrefix = 'input-password-icon_eye-';
const EyeIconClosedSuffix = 'closed';
const EyeIconOpenSuffix = 'open';
const InputTypePassword = 'password';
const InputTypeText = 'text';

@Component({
  name: 'InputPassword',
  props: { value: { type: String, required: true } }
})
export default class extends Vue {
  isPasswordVisible = false;

  get type() {
    return this.isPasswordVisible ? InputTypeText : InputTypePassword;
  }

  get model() {
    return this.value;
  }

  set model(value) {
    this.$emit('input', value);
  }

  get eyeIconClassName() {
    const suffix = this.isPasswordVisible ? EyeIconOpenSuffix : EyeIconClosedSuffix;
    return EyeIconClassNamePrefix + suffix;
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  dispatchSubmitEvent() {
    this.$emit('submit');
  }
}
</script>

<style lang="stylus">
.input-password {
  &-icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;

    &_eye-open {
      background-image: url('../../assets/icons/open-eye.png');
    }

    &_eye-closed {
      background-image: url('../../assets/icons/closed-eye.png');
    }
  }
}
</style>
