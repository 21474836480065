<template>
  <el-button type="primary" :disabled="disabled" @click="dispatchClickEvent" class="page-login-video-stream-button" v-text="$tt('activate_webcam')" />
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: { disabled: { type: Boolean, default: false } }
})
export default class PageLoginVideoStreamButton extends Vue {
  dispatchClickEvent() {
    this.$emit('click');
  }
}
</script>

<style>
.page-login-video-stream-button {
  width: 100%;
}
</style>
