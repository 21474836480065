<template>
  <h1 class="page-login-header" v-text="$tf(message)"></h1>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import { isVideoAuthenticationEnabled } from './page-login-helpers';

@Component({
  props: { authenticationType: { type: String, required: true } }
})
export default class PageLoginHeader extends Vue {
  get message() {
    return isVideoAuthenticationEnabled(this.authenticationType) ? 'activate_webcam_for_face_recognition' : 'sign_in';
  }
}
</script>

<style lang="stylus">
.page-login-header {
  margin: 0 !important;
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.95);
  line-height: 1.3;
}
</style>
